import { useTranslation } from "../../locale";

export default function LinkFormatted({ scope = "", link, title }) {
  const { t } = useTranslation(scope);
  return (
    <a href={link} target="_blank">
      ${t(title)}
    </a>
  );
}
