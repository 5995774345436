import React from "react";
import ListItem from "./LogListItem";

export default function LogsList({ logs, parameters }) {
  return (
    <div className="logs-list" data-testid="logs-list">
      {logs.map((log, index) => (
        <ListItem key={index} log={log} parameters={parameters} />
      ))}
    </div>
  );
}
