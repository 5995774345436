import { of } from "rxjs";
import demoShipment from "../__mocks__/zpl-test-data/shipment.json";
import { delay } from "rxjs/operators";
import { ajax } from "rxjs/ajax";

export class Api {
  createZipCodeParams(zipCode) {
    const trimmed = (zipCode || "").trim();
    return trimmed ? `?zip_code=${trimmed}` : "";
  }

  getShipmentDetails(shipmentId, zipCode) {
    const params = this.createZipCodeParams(zipCode);
    const endpoint = `/api/shipments/${shipmentId}${params}`;

    if (params === "") {
      return ajax.getJSON(endpoint, { accept: "application/json" });
    }

    return ajax.getJSON(endpoint);
  }
}

class MockedApi {
  getShipmentDetails(shipmentId) {
    this.log(`function call: getShipmentDetails(${shipmentId})`);
    return of(demoShipment).pipe(delay(1000));
  }

  log(msg) {
    const style = "color:red;font-weight:bold;border:solid;padding:1em;";
    console.log(`%c[MockService] ${msg}`, style);
  }
}

const mocked = process.env.REACT_APP_USE_MOCKED_DATA === "true";
export const ApiClient = mocked ? new MockedApi() : new Api();
