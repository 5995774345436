import React, { useCallback } from "react";
import TriggerIcon from "./trgigger-icon";

export default function Trigger({
  label,
  value,
  onChange,
  "data-testid": testId,
}) {
  const onClickFn = useCallback(() => {
    onChange(!value);
  }, [value, onChange]);

  return (
    <button
      onClick={onClickFn}
      role="button"
      className="selector-trigger"
      data-testid={testId}
    >
      <label>{label}</label>
      <TriggerIcon isOpen={value} />
    </button>
  );
}
