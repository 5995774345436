import React from "react";
import { useTranslation } from "../../locale";
import { TruckFast } from "@dx/icons";
import ConditionalFragment from "../../components/conditional";

import "./stops-left.css";

function getTranslationKey(stopsLeft, maxVisibleStops) {
  if (stopsLeft > maxVisibleStops) {
    return "TooManyStopsLeft";
  }
  return stopsLeft === 0 ? "NextStop" : "StopsLeft";
}

export default function ShipmentStopsLeft({ shipment }) {
  const stopsLeft = shipment.stopsLeft;
  const maxVisibleStops = Number(process.env.REACT_APP_MAX_VISIBLE_STOPS);
  const count = Math.min(stopsLeft, maxVisibleStops);
  const { t } = useTranslation("Status", { count: count });

  const translationKey = getTranslationKey(stopsLeft, maxVisibleStops);

  return (
    <ConditionalFragment condition={stopsLeft !== null && stopsLeft >= 0}>
      <span className="stops-left" data-testid="shipment-stops-left">
        <TruckFast /> {t(translationKey)}
      </span>
    </ConditionalFragment>
  );
}
