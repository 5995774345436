import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LoadingWrapper from "../../components/loading/LoadingWrapper";
import ShipmentEvents from "./ShipmentEvents";
import ShipmentLogs from "./shipment-logs/ShipmentLogs";
import { fetchShipment, setZipCode } from "../../store/actions";
import Waiting from "./loading/waiting";
import FailedLoading from "./loading/failed";
import Timeline from "../../components/timeline/Timeline";
import { Session } from "../../service/session";
import { useParams } from "react-router-dom";
import Panel from "../../components/panel/Panel";
import ShipmentHeader from "../../components/header/ShipmentHeader";
import ShipmentStatusPanel from "./status-panel";

import "./ParcelPage.css";

function ParcelPage({
  state,
  zipCode,
  zipCodeEnteredByUser,
  setZipCode,
  shipment,
  fetchShipment,
}) {
  const { shipmentNumber: shipmentId } = useParams();

  const [showError, setError] = useState(false);
  const [iteration, setIteration] = useState(0);

  useEffect(() => {
    fetchShipment(shipmentId, zipCode || Session.getZipCode(shipmentId));
    setError(zipCodeEnteredByUser && shipment.askZipCode);
  }, [shipmentId, zipCode, fetchShipment, iteration]);

  const onZipCodeChanged = (value) => {
    if (value === zipCode) {
      setIteration(iteration + 1);
    } else {
      setZipCode(value);
    }
  };

  return (
    <LoadingWrapper
      loading={state.loading}
      error={state.error}
      className="waiting-page"
      testId="parcel-page"
      waiting={<Waiting />}
      fail={
        <FailedLoading
          shipmentId={shipmentId}
          error={state?.error}
          reload={fetchShipment}
        />
      }
    >
      <Panel className="parcel-page main-panel">
        <ShipmentHeader shipmentNumber={shipmentId} />
        <ShipmentStatusPanel shipment={shipment} />
        <Panel className="data-panel">
          <Timeline steps={shipment.checkPoints} />
          <ShipmentLogs logs={shipment.logs} parameters={shipment.meta} />
        </Panel>
        <Panel className="data-panel">
          <ShipmentEvents
            events={shipment.events}
            askZipCode={shipment.askZipCode}
            setZipCode={onZipCodeChanged}
            showError={showError}
          />
        </Panel>
      </Panel>
    </LoadingWrapper>
  );
}

export default connect(
  ({ shipment, state, zipCode, zipCodeEnteredByUser }) => ({
    shipment,
    state,
    zipCode,
    zipCodeEnteredByUser,
  }),
  { fetchShipment, setZipCode },
)(ParcelPage);
