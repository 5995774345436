import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import "rxjs";
import "./locale";
import "./index.css";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_SENTRY_ENV,
  autoSessionTracking: false, // Fixing CORS issue, see https://github.com/getsentry/sentry-javascript/issues/2859
  tracesSampleRate: 1.0,
  denyUrls: [
    // Akamai injected code
    /akam\//i,
    // Chrome extensions
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Safari extensions
    /^safari-extension:\/\//i,
    /^safari-web-extension:\/\//i,
    // Firefox extensions
    /^moz-extension:\/\//i,
  ],
});

Sentry.setTag("application", "Parcel Tracking");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

reportWebVitals();
