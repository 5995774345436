import Text from "../text/Text";
import { TextInput, TextInputType } from "@dx/react-textfield";
import { CrossCircleFilled } from "@dx/icons";
import React, { useState } from "react";
import "./ZipCodeForm.css";
import { useTranslation } from "react-i18next";
import { MessageType } from "@dx/react-input";
import { SecondaryButton } from "@dx/react-button";

function ZipCodeForm({ showError, onSubmit }) {
  const [value, setValue] = useState("");
  const { t } = useTranslation("Info");
  const submit = () => value && onSubmit(value);
  const message = showError
    ? {
        type: MessageType.ERROR,
        icon: <CrossCircleFilled />,
        label: t("InvalidZipCode"),
      }
    : {};

  return (
    <div className="zip-code-form" data-testid="zip-code-form">
      <Text className="zip-code-title" dataTestId="title">
        {t("ProvideZipCode")}
      </Text>
      <div className="form" data-testid="controls-form">
        <TextInput
          data-testid="zip-code-text"
          label={t("ZipCode")}
          placeholder={t("ZipCode")}
          type={TextInputType.TEXT}
          required={true}
          value={value}
          message={message}
          onChange={(evt) => setValue(evt.target.value)}
          onKeyPress={(evt) => {
            if (evt.key === "Enter") {
              submit();
            }
          }}
        />
        <SecondaryButton
          data-testid="zip-code-button"
          className="button"
          label={t("ShowMore")}
          onClick={submit}
        />
      </div>
    </div>
  );
}

export default ZipCodeForm;
