import i18nNext from "i18next";
import moment from "moment-timezone";
import { getCurrentLocale } from "../locale";

const isTomorrow = (date) => date.isSame(moment().add(1, "day"), "day");
const isToday = (date) => date.isSame(moment(), "day");
const localeMoment = (date) => moment(date).locale(getCurrentLocale());

function convertDateFormat(inputDate) {
  const date = moment(inputDate, "DD/MM/YYYY");
  return date.format("Do MMMM, YYYY");
}

export function formatDate(date) {
  const from = localeMoment(date);
  const params = {
    date: convertDateFormat(from.format("L")),
    start: from.format("LT"),
  };

  return i18nNext.t("DateTime:GenericDate", params);
}

export function formatDateTimeRange(start, end) {
  const from = localeMoment(start);
  const to = localeMoment(end);
  const params = {
    date: convertDateFormat(from.format("L")),
    start: from.format("LT"),
    end: to.format("LT"),
  };

  if (isToday(from)) {
    return i18nNext.t("DateTime:TodayRange", params);
  }

  if (isTomorrow(from)) {
    return i18nNext.t("DateTime:TomorrowRange", params);
  }

  return i18nNext.t("DateTime:GenericRange", params);
}
