function toCamelCase(name) {
  return name.replace(/_([a-zA-Z])/g, (g) => g[1].toUpperCase());
}

function camelcaseObject(value) {
  if (typeof value !== "object") {
    return value;
  }

  return Object.entries(value)
    .map(([key, value]) => ({ [toCamelCase(key)]: camelcaseKeys(value) }))
    .reduce((acc, curr) => Object.assign(acc, curr), {});
}

export default function camelcaseKeys(value) {
  if (value === null) return null;

  if (Array.isArray(value)) {
    return value.map((v) => camelcaseKeys(v));
  }

  return camelcaseObject(value);
}
