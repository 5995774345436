import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import "./TimelineStep.css";
import { Checkmark, Cross } from "@dx/icons";

const StatusIcons = {
  succeed: Checkmark,
  exception: Cross,
  failed: Cross,
  returned: Cross,
};

function StatusIcon({ value }) {
  const StatusIcon = StatusIcons[value];
  return (
    StatusIcon && (
      <StatusIcon
        className="img"
        data-testid={value}
        disableAria={true}
        color=""
      />
    )
  );
}

export default function TimelineStep({ active = false, title, status }) {
  const { t } = useTranslation("Timeline");
  const statusId = status && status.toLowerCase();

  return (
    <div
      className={classnames("timeline-step", statusId, { active })}
      data-testid="timeline-step"
    >
      <div className="icon" data-testid="icon">
        <span className="line left-line" data-testid="left-bar" />
        <span className="checkpoint">
          <StatusIcon value={statusId} active={active} />
        </span>
        <span className="line right-line" data-testid="right-bar" />
      </div>
      <span className="title" data-testid="title">
        {t(title)}
      </span>
    </div>
  );
}
