import React from "react";
import Svg from "./svg";

export default function ChevronDownIcon(props) {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M2.859 7.4751C3.15197 7.18213 3.62658 7.18213 3.91955 7.4751L11.4694 15.0249C11.7616 15.3171 12.2377 15.3171 12.5299 15.0249L20.0804 7.4751C20.3734 7.18213 20.848 7.18213 21.141 7.4751C21.434 7.76807 21.434 8.24268 21.141 8.53565L13.5905 16.0854C12.713 16.9629 11.2863 16.9629 10.4088 16.0854L2.85901 8.53565C2.71252 8.38917 2.63928 8.19727 2.63928 8.00538C2.63928 7.81349 2.71252 7.6216 2.859 7.4751Z"
        fill="#2D343E"
      />
    </Svg>
  );
}
