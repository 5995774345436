import React from "react";
import "./Timeline.css";
import TimelineStep from "./TimelineStep";
import getActiveTimelineIndex from "../../service/shipment";

export default function Timeline({ steps }) {
  if (!steps || !steps.length) {
    return <></>;
  }
  const activeIndex = getActiveTimelineIndex(steps);

  return (
    <div className="timeline" data-testid="timeline">
      {steps.map((step, index) => (
        <TimelineStep
          key={index}
          title={step.title}
          status={step.status}
          active={index === activeIndex}
          data-testid={`step step-${index}`}
        />
      ))}
    </div>
  );
}
