export const FETCHING_SHIPMENT = "shipment/fetching";
export const FETCH_SHIPMENT = "shipment/fetch";
export const FETCH_SHIPMENT_REJECTED = "shipment/fetch/error";
export const SET_SHIPMENT = "shipment/fetch/success";
export const SET_ZIP_CODE = "shipment/zip-code";

const createAction = (type, payload) => ({ type, payload });

export const fetchShipment = (shipmentId, zipCode) =>
  createAction(FETCH_SHIPMENT, { shipmentId, zipCode });

export const fetchingShipment = (shipmentId, zipCode) =>
  createAction(FETCHING_SHIPMENT, { shipmentId, zipCode });

export const setZipCode = (zipCode) => createAction(SET_ZIP_CODE, { zipCode });

export const setShipment = (shipment) =>
  createAction(SET_SHIPMENT, { shipment });

export const fetchShipmentError = (error) =>
  createAction(FETCH_SHIPMENT_REJECTED, error);
