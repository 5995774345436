import React, { useMemo } from "react";
import { changeLanguage, getCurrentLocale, getLanguages } from "../../locale";
import { useTranslation } from "react-i18next";
import Selector from "../selector";
import { Checkmark } from "@dx/icons";
import classNames from "classnames";

import "./LanguageSelector.css";

function LanguageOption({ id, label, active, onSelect }) {
  return (
    <button
      className={classNames("language-option", { active })}
      onClick={() => onSelect(id)}
      data-testid="language-option"
    >
      <Checkmark className="icon" />
      <label>{label}</label>
    </button>
  );
}

export default function LanguageSelector() {
  const currentLocale = getCurrentLocale();
  const { t } = useTranslation();
  const languages = getLanguages();
  const options = useMemo(
    () =>
      languages.map((lng) => ({
        id: lng,
        label: t(`Language:${lng}`),
        active: currentLocale === lng,
      })),
    [languages, t, currentLocale],
  );

  return (
    <Selector
      className="language-selector"
      values={options}
      label={t(`LanguageShort:${currentLocale}`)}
      view={LanguageOption}
      onChange={changeLanguage}
    />
  );
}
