import React from "react";
import { ShipmentEventContent } from "../../components/event/ShipmentEvent";
import ConditionalFragment from "../../components/conditional";
import { useTranslation } from "react-i18next";

export default function ShipmentDeliveryTime({ deliveryTime }) {
  const { t } = useTranslation("TrackingEvent");

  return (
    <div className="shipment-time" data-testid="shipment-delivery-time">
      <ConditionalFragment condition={deliveryTime?.title}>
        {t(deliveryTime?.title)}{" "}
        <ShipmentEventContent
          event={deliveryTime}
          data-testid="shipment-delivery-item"
        />
      </ConditionalFragment>
    </div>
  );
}
