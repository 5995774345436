import React from "react";
import { SecondaryButton } from "@dx/react-button";
import { translate, useTranslation } from "../../../locale";
import { useNavigate } from "react-router-dom";
import Text from "../../../components/text/Text";
import Header from "../../search/Header";
import "./waiting.css";

export default function FailedLoading({ shipmentId, error, reload }) {
  const { t } = useTranslation("Info", { shipmentId });
  const navigate = useNavigate();
  const ERRORS = {
    404: {
      message: "ErrorShipmentNotFound",
      retry: () => navigate("/"),
    },
    any: {
      message: "ErrorShipmentLoading",
      retry: () => reload(shipmentId),
    },
  };
  const ERROR = ERRORS[error?.status || "any"] || ERRORS.any;

  return (
    <div className="waiting-page">
      <Header />
      <div className="waiting-form">
        <Text dataTestId="error-message">{t(ERROR.message)}</Text>
        <SecondaryButton
          data-testid="button-reload"
          label={translate("App:TryAgain")}
          onClick={ERROR.retry}
        />
      </div>
    </div>
  );
}
