import React from "react";
import classNames from "classnames";
import "./badge.css";

export default function Badge({ className = "", children }) {
  return (
    <i data-testid="badge" className={classNames("badge", className)}>
      {children}
    </i>
  );
}
