import React, { useEffect } from "react";

export default function useOutsideAlerter(ref, onBlur) {
  useEffect(() => {
    const handler = (event) =>
      ref.current && !ref.current.contains(event.target) && onBlur();

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [ref]);
}
