import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ShipmentLogs.css";
import Trigger from "../../../components/selector/tirgger";
import LogsList from "./LogsList";

export default function ShipmentLogs({ logs, parameters }) {
  const { t } = useTranslation("");
  const [isOpen, setIsOpen] = useState(false);
  const label = t("Info:Title");
  return (
    <div className="shipment-logs">
      <Trigger
        label={label}
        value={isOpen}
        onChange={setIsOpen}
        data-testid="shipment-logs-trigger"
      />
      {isOpen && <LogsList logs={logs} parameters={parameters} />}
    </div>
  );
}
