import React from "react";
import ChevronUpIcon from "../../assets/chevron-up";
import ChevronDownIcon from "../../assets/chevron-down";

export default function TriggerIcon({ isOpen = false }) {
  if (isOpen) {
    return <ChevronUpIcon className="icon" />;
  }

  return <ChevronDownIcon className="icon" />;
}
