import React from "react";
import Text from "../text/Text";
import "./ShipmentEvent.css";
import { FormattedValue } from "../format";
import { useTranslation } from "react-i18next";

export function ShipmentEventContent({ event }) {
  return <FormattedValue scope="TrackingEvent" {...event} />;
}

export default function ShipmentEvent({ event }) {
  const { t } = useTranslation("TrackingEvent");

  return (
    <div className="shipment-event" data-testid="shipment-event">
      <Text dataTestId="title" className="h4 title">
        {t(event.title)}:
      </Text>
      <Text dataTestId="content">
        <ShipmentEventContent event={event} />
      </Text>
    </div>
  );
}
