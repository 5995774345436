import React from "react";
import Svg from "./svg";

export default function ChevronUpIcon(props) {
  return (
    <Svg height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M2.63928 15.9935C2.63928 15.8016 2.71252 15.6097 2.85901 15.4633L10.4088 7.91345C11.2863 7.03601 12.713 7.03601 13.5905 7.91345L21.141 15.4633C21.434 15.7562 21.434 16.2308 21.141 16.5238C20.848 16.8168 20.3734 16.8168 20.0804 16.5238L12.5299 8.974C12.2377 8.68176 11.7616 8.68176 11.4694 8.974L3.91955 16.5238C3.62658 16.8168 3.15197 16.8168 2.859 16.5238C2.71252 16.3773 2.63928 16.1854 2.63928 15.9935Z"
        fill="#2D343E"
      />
    </Svg>
  );
}
