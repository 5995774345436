import React, { useRef, useState } from "react";
import classNames from "classnames";
import useOutsideAlerter from "../../service/outside-alter";
import Trigger from "./tirgger";
import ListView from "../list-view";

import "./selector.css";

function DefaultView() {
  return <span>EMPTY</span>;
}

export default function Selector({
  className,
  label,
  values,
  view = DefaultView,
  onChange,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);
  useOutsideAlerter(ref, () => setIsOpen(false));

  return (
    <div
      ref={ref}
      className={classNames("selector", className)}
      data-testid="selector"
    >
      <Trigger
        label={label}
        value={isOpen}
        onChange={setIsOpen}
        data-testid="selector-trigger"
      />
      {isOpen && (
        <div className="selector-context-menu">
          <div className="selector-options">
            <ListView view={view} values={values} onSelect={onChange} />
          </div>
        </div>
      )}
    </div>
  );
}
