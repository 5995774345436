import React from "react";
import ShipmentEvent from "../../components/event/ShipmentEvent";
import ZipCodeForm from "../../components/zipcode/ZipCodeForm";
import "./ShipmentEvents.css";

export default function ShipmentEvents({
  events,
  askZipCode,
  setZipCode,
  showError,
}) {
  return (
    <div className="shipment-events" data-testid="shipment-event-details">
      {(events || []).map((evt, index) => (
        <ShipmentEvent
          event={evt}
          key={index}
          data-testid="shipment-event-item"
        />
      ))}
      {askZipCode ? (
        <ZipCodeForm showError={showError} onSubmit={setZipCode} />
      ) : (
        <></>
      )}
    </div>
  );
}
