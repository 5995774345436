import TextFormatted from "./TextFormatted";
import DateTimeFormatted from "./DateTimeFormatted";
import DateTimeRangeFormatted from "./DateTimeRangeFormatted";
import AddressFormatted from "./AddressFormatted";
import LinkFormatted from "./LinkFormatted";

const formatters = {
  LINK: LinkFormatted,
  TEXT: TextFormatted,
  DATE_TIME: DateTimeFormatted,
  DATE_TIME_RANGE: DateTimeRangeFormatted,
  ADDRESS: AddressFormatted,
};

export function FormattedValue({ format = "TEXT", ...rest }) {
  const Formatter =
    formatters[format?.toUpperCase() || "TEXT"] || TextFormatted;
  return <Formatter format={format} {...rest} />;
}
