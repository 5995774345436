import i18nNext from "i18next";
import {
  initReactI18next,
  Trans,
  useTranslation as useTranslationI18n,
} from "react-i18next";

import "moment/locale/de";
import "moment/locale/nl";
import "moment/locale/it";
import "moment/locale/fr";
import "moment/locale/en-gb";

import LanguageDetector from "i18next-browser-languagedetector";

import en from "./en-gb.json";
import de from "./de.json";
import fr from "./fr.json";
import it from "./it.json";
import nl from "./nl.json";

import moment from "moment-timezone";
import Text from "../components/text/Text";
import { useMemo } from "react";
import { formatDate, formatDateTimeRange } from "../service/formatters";

const resources = {
  "en-GB": en,
  de,
  fr,
  it,
  nl,
};
const locales = Object.keys(resources);

export const getLanguages = () => locales;
export const getCurrentLocale = () =>
  i18nNext.languages.find((lng) => locales.indexOf(lng) !== -1);

i18nNext
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en-GB",
    resources,
    interpolation: { escapeValue: false },
  })
  .then(() => moment.locale(i18nNext.language));

i18nNext.on("languageChanged", (lng) => moment.locale(lng));

const formatters = {
  date_time: ({ dateAt }) => formatDate(dateAt),
  date_time_range: ({ fromAt, toAt }) => formatDateTimeRange(fromAt, toAt),
  text: ({ content }) => content,
  link: ({ title, link }, t) =>
    `<a href="${link}" target="_blank">${t(title)}</a>`,
};

export const changeLanguage = (lng) => i18nNext.changeLanguage(lng);

export function format(value, t) {
  if (typeof value === "object") {
    return formatters[value?.format?.toLowerCase() || "text"](value, t);
  }
  return value;
}

export function mapTranslationParameters(parameters = {}, t) {
  if (!parameters) return {};
  return Object.entries(parameters)
    .map(([key, value]) => ({ [key]: format(value, t) }))
    .reduce((acc, val) => Object.assign(acc, val), {});
}

const useProxiedTranslation = (ns, parameters = {}) => {
  const tn = useTranslationI18n();
  const params = useMemo(
    () => mapTranslationParameters(parameters, tn),
    [parameters, tn],
  );
  return {
    t: (p) => tn.t(`${ns}:${p}`, params),
  };
};
export const useTranslation = (ns = "", parameters) =>
  parameters
    ? useProxiedTranslation(ns, parameters)
    : useTranslationI18n(ns, parameters);

export function translate(text, namespace, parameters) {
  return useTranslation(namespace, parameters).t(text);
}

const TranslationComponents = {
  strong: <strong className="strong" />,
  a: <a />,
};

export function Translation(props) {
  const { namespace, text, className, parameters } = props;
  const { t } = useTranslation();
  const mappedParams = useMemo(() => {
    return mapTranslationParameters(parameters, t);
  }, [parameters, t]);

  return (
    <Text
      className={className}
      tag={props.tag}
      dataTestId={props["data-testid"]}
    >
      {props.prefix}
      <Trans
        ns={namespace}
        t={t}
        i18nKey={text}
        values={mappedParams}
        components={TranslationComponents}
      />
    </Text>
  );
}

export const i18n = i18nNext;
