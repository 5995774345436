import { useTranslation } from "../../locale";
import React from "react";
import "./ShipmentHeader.css";
import LanguageSelector from "../language/LanguageSelector";

export default function ShipmentHeader({ shipmentNumber }) {
  const { t } = useTranslation("Shipment");

  return (
    <div className="shipment-header">
      <div className="title">
        <div>{t("Number")}</div>
        <div className="number">
          <div>{shipmentNumber}</div>
        </div>
      </div>
      <LanguageSelector />
    </div>
  );
}
