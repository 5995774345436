import {
  FETCH_SHIPMENT,
  FETCH_SHIPMENT_REJECTED,
  SET_SHIPMENT,
  SET_ZIP_CODE,
} from "../actions";
import { Session } from "../../service/session";

const EMPTY_SHIPMENT = {
  delivery_time: {},
  number: "",
  status: "",
  checkpoints: [],
  events: [],
  logs: [],
};
const ROOT_STATE = {
  state: {
    loading: true,
    error: "",
  },
  zipCode: "",
  zipCodeEnteredByUser: false,
  shipment: EMPTY_SHIPMENT,
};
const MUTATIONS = {
  [FETCH_SHIPMENT]({ state }) {
    return {
      ...state,
      shipment: EMPTY_SHIPMENT,
      state: { loading: true, error: "" },
    };
  },
  [SET_SHIPMENT]({ state, payload }) {
    return {
      ...state,
      state: { loading: false, error: "" },
      shipment: payload.shipment,
    };
  },
  [FETCH_SHIPMENT_REJECTED]({ state, payload }) {
    return {
      ...state,
      shipment: EMPTY_SHIPMENT,
      state: { loading: false, error: payload },
    };
  },
  [SET_ZIP_CODE]({ state, payload }) {
    const { zipCode } = payload;
    const zipCodeEnteredByUser = true;

    Session.setZipCode(state.shipment.number, zipCode);
    return { ...state, zipCode, zipCodeEnteredByUser };
  },
};

export function rootProducer(state = ROOT_STATE, action) {
  const mutation = MUTATIONS[action.type] || (() => state);
  return mutation({ state, payload: action.payload });
}
