import React, { useMemo } from "react";
import Panel from "../../components/panel/Panel";
import { ShipmentStatusBadge } from "./ShipmentStatus";
import ConditionalFragment from "../../components/conditional";
import HorizontalLine from "../../components/line";
import ShipmentDeliveryTime from "./ShipmentDeliveryTime";
import ShipmentStopsLeft from "./ShipmentStopsLeft";
import { Translation } from "../../locale";

export default function ShipmentStatusPanel({ shipment }) {
  const showFooter = useMemo(
    () => shipment?.description?.length > 0 || shipment.stopsLeft >= 0,
    [shipment],
  );

  return (
    <Panel className="data-panel">
      <ShipmentStatusBadge shipment={shipment} />
      <ShipmentDeliveryTime deliveryTime={shipment.deliveryTime} />

      <ConditionalFragment condition={showFooter}>
        <HorizontalLine />
        <ShipmentStopsLeft shipment={shipment} />
        <Translation
          namespace="Description"
          text={shipment.description || ""}
          parameters={shipment.meta}
        />
      </ConditionalFragment>
    </Panel>
  );
}
