export const Session = {
  getZipCode(shipmentNumber) {
    try {
      return sessionStorage.getItem(`zipcode${shipmentNumber}`) || "";
    } catch (e) {
      return "";
    }
  },

  setZipCode(shipmentNumber, zipCode) {
    try {
      sessionStorage.setItem(`zipcode${shipmentNumber}`, zipCode);
    } catch (e) {}
  },
};
