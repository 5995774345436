import React, { Fragment } from "react";
import Text from "../text/Text";

export default function AddressFormatted({
  addressLine1,
  addressLine2,
  contactName,
  city,
  country,
  zipcode,
}) {
  return (
    <Fragment>
      <Text className="h5" dataTestId="address-contact">
        {contactName}
      </Text>
      <Text dataTestId="address-line1">{addressLine1}</Text>
      <Text dataTestId="address-line2">{addressLine2}</Text>
      <Text dataTestId="address-line3">{`${zipcode} ${city},${country}`}</Text>
    </Fragment>
  );
}
