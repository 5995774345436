import i18n from "i18next";
import moment from "moment-timezone";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SearchPage from "./pages/search/SearchPage";
import ParcelPage from "./pages/parcel-details/ParcelPage";
import Footer from "./components/footer/Footer";

import "./App.css";
import "@dx/all/atom.min.css";

const router = createBrowserRouter([
  { path: "/", element: <SearchPage /> },
  { path: "/shipments", element: <SearchPage /> },
  { path: "/shipments/:shipmentNumber", element: <ParcelPage /> },
]);

export default function TrackingApp() {
  moment.locale(i18n.language);

  return (
    <>
      <div className="tracking-app" data-testid="app">
        <RouterProvider router={router} />
        <Footer />
      </div>
    </>
  );
}
