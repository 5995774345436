import React from "react";
import { TruckFast } from "@dx/icons";
import { useTranslation } from "../../../locale";
import Text from "../../../components/text/Text";
import Header from "../../search/Header";
import "./waiting.css";

export default function Waiting() {
  const { t } = useTranslation("App");

  return (
    <div data-testid="waiting-page">
      <Header />
      <div className="waiting-form">
        <Text>{t("Loading")}</Text>
        <TruckFast />
      </div>
    </div>
  );
}
