import { combineEpics, ofType } from "redux-observable";
import {
  FETCH_SHIPMENT,
  FETCHING_SHIPMENT,
  fetchingShipment,
  fetchShipmentError,
  setShipment,
} from "../actions";
import { map, mergeMap, catchError, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { ApiClient } from "../../service/api";
import akamaiChallenge from "../../service/akamai";
import camelcaseKeys from "../../service/camelcase";

export const fetchShipmentEpic = (action$) =>
  action$
    .pipe(
      ofType(FETCH_SHIPMENT),
      switchMap(({ payload }) =>
        of(fetchingShipment(payload.shipmentId, payload.zipCode)),
      ),
    )
    .pipe(
      ofType(FETCHING_SHIPMENT),
      mergeMap(({ payload }) =>
        ApiClient.getShipmentDetails(payload.shipmentId, payload.zipCode).pipe(
          map(camelcaseKeys),
          map((shipment) => setShipment(shipment)),
          catchError((err) => {
            akamaiChallenge(err);
            return of(fetchShipmentError(err));
          }),
        ),
      ),
    );
export default combineEpics(fetchShipmentEpic);
