import Text from "../../../components/text/Text";
import { formatDate } from "../../../service/formatters";
import { Translation } from "../../../locale";
import { TextPreset } from "@dx/react-text";
import React from "react";

export default function ListItem({ log, parameters }) {
  return (
    <div className="logs-list-item" data-testid="log-list-item">
      <Text className="list-item-header" dataTestId="date">
        {formatDate(log.createdAt, true)}
      </Text>
      <Translation
        preset={TextPreset.body}
        text={log.description}
        namespace="Tracking"
        parameters={parameters}
        data-testid="desc"
        className="list-item-description"
      />
    </div>
  );
}
