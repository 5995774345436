import { Fragment } from "react";

export default function ListView({ values = [], view: ItemView, ...rest }) {
  return (
    <Fragment>
      {values.map((value) => (
        <ItemView key={value.id} {...value} {...rest} />
      ))}
    </Fragment>
  );
}
