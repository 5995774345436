import React from "react";
import "./Header.css";
import LanguageSelector from "../../components/language/LanguageSelector";
import { useTranslation } from "react-i18next";

export default function Header() {
  const { t } = useTranslation("App");
  return (
    <div className="header">
      <div className="title">{t("App:Name")}</div>
      <LanguageSelector />
    </div>
  );
}
