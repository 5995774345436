function challengeUnavailable() {
  return (
    !window.ak_chlge || typeof window.ak_chlge.showChallenge !== "function"
  );
}

export default function akamaiChallenge(error) {
  if (error?.status !== 428 || challengeUnavailable()) {
    return;
  }
  window.ak_chlge.showChallenge(error?.response, error?.request?.url);
}
