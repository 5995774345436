import React, { useState } from "react";
import { TextInput, TextInputType } from "@dx/react-textfield";
import { translate } from "../../locale";
import { PrimaryButton } from "@dx/react-button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./SearchPage.css";
import Header from "./Header";
import Text from "../../components/text/Text";
import { BoxTransit } from "@dx/icons";

const isEmpty = (shipmentNo) => shipmentNo.trim().length === 0;
const isValid = (shipmentNo) => !`${shipmentNo}`.trim().includes(" ");

export default function SearchPage() {
  const [shipmentNo, setShipmentNo] = useState("");
  const { t } = useTranslation("Info");
  const navigate = useNavigate();

  const onSearch = () => {
    if (isValid(shipmentNo) && !isEmpty(shipmentNo)) {
      navigate(`/shipments/${shipmentNo.trim()}`);
    }
  };

  return (
    <div className="search-page" data-testid="search-page">
      <Header />
      <div className="search-form">
        <div className="search-form icon">
          <BoxTransit />
        </div>
        <div className="search-form items">
          <Text dataTestId="title" className="search-form text">
            {t("PleaseEnterShipmentNumber")}
          </Text>
          <TextInput
            className="search-form input"
            id="shipment-number-textbox"
            data-testid="search-text"
            label={t("ProvideShipmentNumber")}
            onKeyPress={(evt) => evt.key === "Enter" && onSearch()}
            type={TextInputType.TEXT}
            required={true}
            value={shipmentNo}
            onChange={(evt) => setShipmentNo(evt.target.value)}
          />
          <PrimaryButton
            className={"search-form button"}
            onClick={onSearch}
            disabled={!isValid(shipmentNo)}
            data-testid="search-button"
            label={translate("Search", "Info")}
          ></PrimaryButton>
        </div>
      </div>
    </div>
  );
}
