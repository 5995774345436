import Badge from "../../components/badge";
import getActiveTimelineIndex from "../../service/shipment";
import { useTranslation } from "react-i18next";

export function ShipmentStatusBadge({ shipment }) {
  const activeIndex = getActiveTimelineIndex(shipment.checkPoints);
  const currentPointTitle = shipment.checkPoints[activeIndex].title;
  const { t } = useTranslation("Timeline");

  return <Badge>{t(currentPointTitle)}</Badge>;
}
