import React from "react";
import { useTranslation } from "react-i18next";
import Link from "../link/Link";
import "./Footer.css";

function Footer() {
  const { t } = useTranslation("Footer");

  return (
    <footer className="tracking-app-footer" data-testid="footer">
      <div className="links">
        <Link className="footer-link" href={t("Footer:LegalNoticeLink")}>
          {t("LegalNotice")}
        </Link>
        <Link className="footer-link" href={t("Footer:DataPrivacyLink")}>
          {t("DataProtection")}
        </Link>
        <Link className="footer-link" href={t("HelpLink")}>
          {t("Help")}
        </Link>
        <div />
      </div>
      <div className="tracking-app-footer-terms">
        {t("Cookie:TermsOfUseTextPre")}
      </div>
    </footer>
  );
}

export default Footer;
