import React, { useMemo } from "react";

export default function loadingWrapper({
  loading,
  error,
  children,
  waiting,
  fail,
  className,
  testId = "loading-wrapper",
}) {
  const content = useMemo(() => {
    if (error) return fail;
    if (loading) return waiting;
    return children;
  }, [loading, error, children, waiting, fail]);
  return (
    <div data-testid={testId} className={className}>
      {content}
    </div>
  );
}
