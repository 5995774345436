import { useTranslation } from "../../locale";
import { Fragment } from "react";

export default function TextFormatted({
  scope = "",
  content,
  translated = false,
}) {
  const { t } = useTranslation(scope);
  return <Fragment>{translated ? content : t(content)}</Fragment>;
}
